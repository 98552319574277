<template>
  <div class="mod-home">
   <div class="box1">
     <!-- <img width="100%" height="100%" src="../../assets/img/home.jpg" /> -->
      <button v-print="printObj">Print local range</button><div id="loading" v-show="printLoading"></div>

       <div id="printMe" style="background:red;">
             <p>葫芦娃，葫芦娃</p>
             <p>一根藤上七朵花 </p>
             <p>小小树藤是我家 啦啦啦啦 </p>
             <p style="font-size: 20px;">叮当当咚咚当当　浇不大</p>
             <p> 叮当当咚咚当当 是我家</p>
             <p> 啦啦啦啦</p>
             <p>...</p>
             <p>葫芦娃，葫芦娃</p>
             <p>一根藤上七朵花 </p>
             <p>小小树藤是我家 啦啦啦啦 </p>
             <p style="font-size: 20px;">叮当当咚咚当当　浇不大</p>
             <p> 叮当当咚咚当当 是我家</p>
             <p> 啦啦啦啦</p>
             <p>...</p>
             <p>葫芦娃，葫芦娃</p>
             <p>一根藤上七朵花 </p>
             <p>小小树藤是我家 啦啦啦啦 </p>
             <p style="font-size: 20px;">叮当当咚咚当当　浇不大</p>
             <p> 叮当当咚咚当当 是我家</p>
             <p> 啦啦啦啦</p>
             <p>...</p>
             <p>葫芦娃，葫芦娃</p>
             <p>一根藤上七朵花 </p>
             <p>小小树藤是我家 啦啦啦啦 </p>
             <p style="font-size: 20px;">叮当当咚咚当当　浇不大</p>
             <p> 叮当当咚咚当当 是我家</p>
             <p> 啦啦啦啦</p>
             <p>...</p>
             <p>葫芦娃，葫芦娃</p>
             <p>一根藤上七朵花 </p>
             <p>小小树藤是我家 啦啦啦啦 </p>
             <p style="font-size: 20px;">叮当当咚咚当当　浇不大</p>
             <p> 叮当当咚咚当当 是我家</p>
             <p> 啦啦啦啦</p>
             <p>...</p>
             <p>葫芦娃，葫芦娃</p>
             <p>一根藤上七朵花 </p>
             <p>小小树藤是我家 啦啦啦啦 </p>
             <p style="font-size: 20px;">叮当当咚咚当当　浇不大</p>
             <p> 叮当当咚咚当当 是我家</p>
             <p> 啦啦啦啦</p>
             <p>...</p>
             <p>葫芦娃，葫芦娃</p>
             <p>一根藤上七朵花 </p>
             <p>小小树藤是我家 啦啦啦啦 </p>
             <p style="font-size: 20px;">叮当当咚咚当当　浇不大</p>
             <p> 叮当当咚咚当当 是我家</p>
             <p> 啦啦啦啦</p>
             <p>...</p>
             <p>葫芦娃，葫芦娃</p>
             <p>一根藤上七朵花 </p>
             <p>小小树藤是我家 啦啦啦啦 </p>
             <p style="font-size: 20px;">叮当当咚咚当当　浇不大</p>
             <p> 叮当当咚咚当当 是我家</p>
             <p> 啦啦啦啦</p>
             <p>...</p>
         </div>
   </div>
  </div>
</template>

<script>
  export default {
     data() {
    return {
      printLoading: true,
          printObj: {
          id: "printMe",
          popTitle: '',  // 打印的标题
          extraCss: '',  // 打印可引入外部的一个 css 文件
          extraHead: '',  // 打印头部文字
          beforeOpenCallback (vue) {
            vue.printLoading = true
            // console.log('打开之前')
          },
          openCallback (vue) {
            vue.printLoading = false
            // console.log('执行了打印')
          },
          closeCallback (vue) {
            // console.log('关闭了打印工具')
          }
        }
    };
  }
  }
</script>

<style lang="scss" scoped>
.box1 {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}


  @page{
    size:  auto;   /* auto is the initial value */
    margin: 3mm;  /* this affects the margin in the printer settings */
  }

  html{
    background-color: #FFFFFF;
    margin: 0;  /* this affects the margin on the html before sending to printer */
  }

  body{
    border: solid 1px blue ;
    margin: 10mm 15mm 10mm 15mm; /* margin you want for the content */
  }
</style>
